<template>
 <div class="flex gap- items-center text-xs px-4 py-2 rounded-md focus:outline-none bg-gray-400 text-white w-full text-left">
  <div class="pb-1 gap-">
   <div class="">
    <label
     for="location"
     class="col-start-1 flex gap-1 items-center capitalize text-xs font-medium "
     >{{ $t("callqueueTable.start") }}</label
    >
    <div class="col-span-6 row-start-2 flex gap-1 justify-items-center">
     <span class="flex gap-1 items-center"
      ><svg
       xmlns="http://www.w3.org/2000/svg"
       class="h-4 w-4 "
       fill="none"
       viewBox="0 0 24 24"
       stroke="currentColor"
      >
       <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
       /></svg
     ></span>
     <div class="flex gap-1 items-center">
      <div>
       <select
        :disabled="
         (elements && elements.length > 0) ||
         this.$route.name === 'ExpertStatisticsFullQueueReport' ||
         this.$route.name.includes('ExpertStatisticsOutbound')
          ? false
          : true
        "
        v-model="timeStart.dateHours"
        id="location"
        name="location"
        class="text-xs mt-1 text-gray-700 block not-sr-only py-1 w-auto px-1 border-transparent bg-gray-100 rounded-md"
       >
        <option v-for="time in this.timeHours" :key="time" :value="time">{{ time }}</option>
       </select>
      </div>
      <span class="self-center"> : </span>
      <div>
       <select
        :disabled="
         (elements && elements.length > 0) ||
         this.$route.name === 'ExpertStatisticsFullQueueReport' ||
         this.$route.name.includes('ExpertStatisticsOutbound')
          ? false
          : true
        "
        v-model="timeStart.dateMin"
        id="location"
        name="location"
        class="text-xs mt-1 text-gray-700 block not-sr-only py-1 w-auto px-1 border-transparent bg-gray-100 rounded-md"
       >
        <option value="00">00</option>
        <option value="15">15</option>
        <option value="30">30</option>
        <option value="45">45</option>
       </select>
      </div>
     </div>
    </div>
   </div>
   <div class="">
    <label
     for="location"
     class="col-start-1 flex gap-1 capitalize items-center text-xs font-medium "
     >{{ $t("callqueueTable.end") }}</label
    >
    <div class="col-span-6 row-start-2 flex gap-1 inline-flex justify-items-center">
     <span class="flex items-center gap-1"
      ><svg
       xmlns="http://www.w3.org/2000/svg"
       class="h-4 w-4 "
       fill="none"
       viewBox="0 0 24 24"
       stroke="currentColor"
      >
       <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
       /></svg
     ></span>
     <div class="flex gap-1">
      <div>
       <select
        :disabled="
         (elements && elements.length > 0) ||
         this.$route.name === 'ExpertStatisticsFullQueueReport' ||
         this.$route.name.includes('ExpertStatisticsOutbound')
          ? false
          : true
        "
        v-model="timeEnd.dateHours"
        id="location"
        name="location"
        class="text-xs mt-1 text-gray-700 block not-sr-only py-1 w-auto px-1 border-transparent bg-gray-100 rounded-md"
       >
        <option v-for="time in this.timeHours" :key="time" :value="time">{{ time }}</option>
       </select>
      </div>
      <span class="self-center"> : </span>
      <div>
       <select
        :disabled="
         (elements && elements.length > 0) ||
         this.$route.name === 'ExpertStatisticsFullQueueReport' ||
         this.$route.name.includes('ExpertStatisticsOutbound')
          ? false
          : true
        "
        v-model="timeEnd.dateMin"
        id="location"
        name="location"
        class="text-xs mt-1 text-gray-700 block not-sr-only py-1 w-auto px-1 border-transparent bg-gray-100 rounded-md"
       >
        <option value="00">00</option>
        <option value="15">15</option>
        <option value="30">30</option>
        <option value="45">45</option>
       </select>
      </div>
     </div>
    </div>
   </div>
   <div class="mt-5">
    <button
     @click="clearTimeSelector = !clearTimeSelector"
     class="inline-flex items-center px-1 py-1 capitalize rounded border border-gray-300 bg-gray-200 text-xs text-gray-600 hover:bg-gray-300 hover:text-gray-700"
    >
     {{ $t("dashboards.clear") }}
     <font-awesome-icon icon="filter-circle-xmark" class="ml-1 mr-0.5 h-4 w-4 " />
    </button>
   </div>
  </div>
 </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
 name: "TimeSelector",
 props: ["formatDateToTime", "elements"],
 components: {},

 data() {
  return {
   clearTimeSelector: false,

   timeStart: { dateHours: "", dateMin: "00" },
   timeEnd: { dateHours: "", dateMin: "00" },
   day: "",
   hour: "",
   time: [],
   timeHours: [],
   timeStartStr: null,
   timeEndStr: null,
  };
 },
 methods: {
  async helpersTime() {
   try {
    const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/helpers/time/hours`, {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    });
    this.parseTime(res.data);
   } catch (error) {
    console.error(error);
   }
  },
  parseTime(time) {
   for (let index = 0; index < time.length; index++) {
    const element = time[index];
    this.time.push(element.slice(0, -6));
   }

   this.timeHours = this.time.reduce((unique, o) => {
    if (!unique.some((obj) => obj === o)) {
     unique.push(o);
    }
    return unique;
   }, []);
  },
  setTimeFromState() {
   this.timeStart = this.pbxTimeSelectedStart ? this.pbxTimeSelectedStart : this.timeStart;
   this.timeEnd = this.pbxTimeSelectedEnd ? this.pbxTimeSelectedEnd : this.timeEnd;
  },
 },
 mounted() {
  this.helpersTime();
  // this.setTimeFromState();
 },
 watch: {
  timeStart: {
   handler: function (val) {
    var timeStart = val.dateHours + val.dateMin;
    this.timeStartStr = timeStart;
    if (this.timeEndStr && val.dateHours.length > 0) {
     let timeArray = [];
     timeArray.splice(0, 0, timeStart);
     timeArray.splice(1, 0, this.timeEndStr);
     this.$emit("timeSelector", timeArray);
    }
   },
   deep: true,
  },
  timeEnd: {
   handler: function (val) {
    if (this.timeStartStr && val.dateHours.length > 0) {
     var timeEnd = val.dateHours + val.dateMin;
     this.timeEndStr = timeEnd;
     console.log("timestart, timeend: " + this.timeStartStr, ":", timeEnd);
     let timeArray = [];
     timeArray.splice(0, 0, this.timeStartStr);
     timeArray.splice(1, 0, timeEnd);
     this.$store.dispatch("pbxTimeSelected", timeArray);
     this.$emit("timeSelector", timeArray);
    }
   },
   deep: true,
  },
  clearTimeSelector: function (val) {
   if (this.timeStartStr || this.timeEndStr) {
    this.timeStart.dateHours = "";
    this.timeStart.dateMin = "00";
    this.timeEnd.dateHours = "";
    this.timeEnd.dateMin = "00";
    this.timeStartStr = null;
    this.timeEndStr = null;
    this.$store.dispatch("pbxTimeSelected", null);
    this.$emit("timeSelector", []);
   }
  },
 },
 computed: {
  ...mapGetters(["pbxTimeSelectedEnd"]),
 },
};
</script>

<style></style>
